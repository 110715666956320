import axios from "axios";

var moment = require("moment");
moment.locale("RU");

export default {
  state: {
    period: {
      start: null,
      end: null,
    },
    statuses: null,

    tabTableView: {
      items: [],
      fields: null,
    },

    reportData: null,

    tableItems: null,
    fields: null,
    created_at: null,
  },
  mutations: {
    SET_REPORT_DATA(state, payload) {
      state.reportData = payload;
    },
    SET_PERIOD(state, payload) {
      state.period = payload;
    },

    SET_TAB_DATA(state, payload) {
      state.tabData = payload;
    },

    TAB_TABLEVIEW_FIELDS(state, payload) {
      state.tabTableView.fields = payload;
    },
    TAB_TABLEVIEW_ITEMS(state, payload) {
      state.tabTableView.items = payload;
    },

    SET_STAGE_IDS(state, payload) {
      state.stageIds = payload;
    },
    SET_CREATED_AT(state, payload) {
      state.created_at = payload;
    },
  },
  actions: {
    async getPageData({ commit }, reload) {
      let savedPeriod = await axios.get("periods/1");
      savedPeriod = savedPeriod.data;

      if (!savedPeriod) return;

      let period = {
        start: new Date(savedPeriod.start),
        end: new Date(savedPeriod.end),
      };

      commit("SET_PERIOD", period);

      let start = moment(period.start).format("DD.MM.YYYY");
      let end = moment(period.end).format("DD.MM.YYYY");

      let pageId = 6;

      let key = `payroll_report_p${pageId}_${start}-${end}`;

      // get report data

      // try get report data from DB
      let storedReportData = await axios.post("report", {
        key: key,
      });

      if (!storedReportData.data || reload) {
        // get report data from b24
        let newReportData = await axios.post("payroll_report", {
          pageId: 6,
          period: {
            start: start,
            end: end,
          },
        });
        newReportData = newReportData.data;

        // save report data
        storedReportData = await axios.put("report", {
          key: key,
          data: newReportData,
        });
      }

      storedReportData = storedReportData.data; // unpack axios data

      commit("SET_CREATED_AT", storedReportData.created_at);

      storedReportData = storedReportData.data;

      // preparing data
      let indicatorsData = [];
      storedReportData.tabs.forEach((tab) => {
        let result = formatTabData(period, tab.data.indicators, tab.data.users);

        indicatorsData = indicatorsData.concat(result);
      });

      // preparing table
      let fields = [];

      fields.push({
        key: "PERSONAL_PHOTO",
        label: "Сотрудник",
        stickyColumn: true,
      });
      fields.push({ key: "total", label: "Всего", sortable: true });

      let data;
      var users = [];
      indicatorsData.forEach((indicator) => {
        // set fields
        fields.push({
          key: "indicatorId_" + indicator.id,
          label: indicator.title,
          sortable: true,
        });

        indicator.users.forEach((user) => {
          data = {
            items: user.items,
            total: user.total,
            value: user.value,
          };

          user = user.user;
          user.data = data;

          // find user
          let editUser = users.find((val) => {
            return val.ID == user.ID;
          });

          if (editUser) {
            editUser["indicatorId_" + indicator.id] = user.data.items.length;
            editUser.total += +user.data.items.length;
            editUser.items = editUser.items.concat(user.data.items);
          } else {
            user["indicatorId_" + indicator.id] = user.data.items.length;
            user.total = user.data.items.length;
            user.items = user.data.items;

            users.push(user);
          }
        });
      });

      let result = {
        fields,
        users,
        indicatorsData,
      };

      commit("SET_REPORT_DATA", result);
    },
  },
};

function usersPreparation(period, indicators, users) {
  for (let user of users) {
    indicators.forEach((filter) => {
      if (!filter.users) filter.users = [];

      let items = [];
      let value = 0;
      let userId;

      // Search user items
      filter.data.items.forEach((item) => {
        // Find userId in item
        userId = findUserIDInItem(item, filter);
        // Deal for user?
        if (+userId == +user.ID) {
          items.push(item);

          let sum = findSumInItem(item, filter);
          value += +sum;
        }
      });

      if (items.length == 0) {
        return;
      }

      value = operations(value, filter.operations, indicators, userId);

      let chart = createChart(period, filter, items, 0, false);

      // if (!user.data) user.data = {}

      // user.data.items = items;
      // user.data.value = value;
      // user.data.chart = chart;

      // filter.data.users.push(user);

      filter.users.push({
        user,
        items,
        value,
        chart,
      });

      filter.users.sort((a, b) => {
        return b.value - a.value;
      });
    });
  }

  return indicators;
}

function formatTabData(period, indicators, users) {
  let tableFields = [];
  tableFields.push({ key: "date", label: "Дата" });

  for (let indicator of indicators) {
    // Changes on day
    let todaySum = 0;
    let todayItems = [];

    let fieldDate = findDate(indicator);

    indicator.data.items.forEach((item) => {
      let sum = findSumInItem(item, indicator);

      let startToday = new Date();
      startToday.setHours(0, 0, 0, 0);

      let endToday = new Date();
      endToday.setHours(23, 59, 59, 999);

      if (
        new Date(item[fieldDate]) >= startToday &&
        new Date(item[fieldDate]) <= endToday
      ) {
        todayItems.push(item);
        todaySum += +sum;
      }
    });

    // indicator today data
    indicator.todayItems = todayItems;

    indicator.todaySum = operations(todaySum, indicator.operations, indicators);

    // indicator value
    indicator.sum = +indicator.data.value;

    indicator.sum = operations(indicator.sum, indicator.operations, indicators);

    // indicator chart

    let chart = createChart(period, indicator, indicator.data.items);
    indicator.chart = chart;

    // indicator table
    tableFields.push({
      key: String(indicator.id),
      label: indicator.title,
    });

    indicator.data.users = [];

    // Sortin indicators to cols -> druggable
    // commit("SET_FILTERS_COLS", indicator);

    // commit("SET_TAB_DATA", tabData);

    // tab table view
    // commit("TAB_TABLEVIEW_FIELDS", tableFields);

    // let tabTableViewItems = tabTableView(period, tabData.indicators);
    // commit("TAB_TABLEVIEW_ITEMS", tabTableViewItems);
  }

  let returnIndicators = usersPreparation(period, indicators, users);
  return returnIndicators;
}

// Operations in indicator
function operations(value, operations, filters, userBitrixId) {
  if (operations == null) return value;

  operations.forEach((operation) => {
    // Find value operation
    if (operation.from_filter) {
      let item;

      // Find index filter
      var index = filters.findIndex((el) => el.id == operation.from_filter);

      // If user operation
      if (userBitrixId) {
        // Find index filter
        let userIndex = filters[index].users.findIndex(
          (el) => el.data.ID == userBitrixId
        );

        item = filters[index].users[userIndex];
      } else {
        item = filters[index];
      }

      // Find value operation from variable
      var valueV;
      switch (operation.value) {
        case "$value":
          valueV = item.sum;
          break;
        case "$count":
          valueV = item.items.length;
          break;
        case "$items":
          valueV = item.items;
          break;
      }
    } else {
      valueV = operation.value;
    }

    // Logic operation
    switch (operation.action) {
      case "+":
        value += +valueV;
        break;
      case "-":
        value -= +valueV;
        break;
      case "/":
        value /= +valueV;
        break;
      case "*":
        value *= +valueV;
        break;
    }
  });

  return Math.round(value);
}

// Creating widgets and specific view
function createChart(period, filter, items, pointRadius = 4, withCount = true) {
  let chart = {
    labels: [],
    datasets: [],
  };

  let datasetSum = {
    label: "Сумма",
    borderColor: "rgba(0, 220, 125, 0.7)",
    backgroundColor: "rgba(0, 220, 125, 0.1)",
    pointRadius: pointRadius,
    data: [],
  };

  let datasetCount = {
    label: "Количество",
    borderColor: "rgba(126, 179, 255, 0.7)",
    backgroundColor: "rgba(126, 179, 255, 0.1)",
    pointRadius: pointRadius,
    data: [],
  };

  let date;

  var timeDiff = Math.abs(period.end.getTime() - period.start.getTime());
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (diffDays <= 93) {
    let periodStart = new Date(period.start);
    let periodEnd = new Date(period.end);

    while (periodStart <= periodEnd) {
      let value = 0;
      let count = 0;

      date = moment(periodStart).format("DD MMMM");
      chart.labels.push(date);

      items.forEach((item) => {
        let fieldDate = findDate(filter);

        if (
          moment(periodStart).format("DD.MM.YYYY") ==
          moment(item[fieldDate]).format("DD.MM.YYYY")
        ) {
          let sum = findSumInItem(item, filter);

          value += +sum;
          count += 1;
        }
      });

      // value = operations(
      //   value,
      //   filter.operations,
      //   state.filters
      // );

      value = sumFormat(value);

      datasetCount.data.push(count);
      datasetSum.data.push(value);

      periodStart.setDate(periodStart.getDate() + 1);
    }
  } else if (diffDays > 93) {
    let periodStart = new Date(period.start);
    let periodEnd = new Date(period.end);

    while (periodStart <= periodEnd) {
      date = moment(periodStart).format("MM.YYYY");

      let value = 0;
      let count = 0;

      chart.labels.push(date);

      items.forEach((item) => {
        let fieldDate = findDate(filter);

        if (
          moment(periodStart).format("MM.YYYY") ==
          moment(item[fieldDate]).format("MM.YYYY")
        ) {
          let sum = findSumInItem(item, filter);

          value += +sum;
          count += 1;
        }
      });

      // value = operations(
      //   value,
      //   filter.operations,
      //   state.filters
      // );

      value = sumFormat(value);

      datasetCount.data.push(count);
      datasetSum.data.push(value);

      periodStart.setMonth(periodStart.getMonth() + 1);
    }
  }

  if (withCount) {
    chart.datasets.push(datasetCount);
  }

  chart.datasets.push(datasetSum);

  return chart;
}

function checkCurrency(sum) {
  if (sum.toString().includes("|RUB") || sum.toString().includes("|USD")) {
    sum = sum.replace(/[^0-9,.]/g, " ");
  }

  return sum;
}

// Find in
function findSumInItem(item, filter) {
  let sum = 0;
  let fieldSum;

  // Key field sum
  if (filter.field_sum != null) {
    fieldSum = filter.field_sum;
  } else {
    filter.type == "crm.item.list"
      ? (fieldSum = "opportunity")
      : (fieldSum = "OPPORTUNITY");
  }

  // Find value
  if (typeof item[fieldSum] == "string" || typeof item[fieldSum] == "number") {
    sum = item[fieldSum];
  } else if (typeof item[fieldSum] == "object" && item[fieldSum] != null) {
    let key = Object.keys(item[fieldSum])[0];
    sum = item[fieldSum][key];
  }

  // Remove currency from string
  sum = checkCurrency(sum);

  return sum;
}

function findDate(filter) {
  let fieldDate;

  if (filter.field_date != null) {
    fieldDate = filter.field_date;
  } else {
    filter.type == "crm.item.list"
      ? (fieldDate = "begindate")
      : (fieldDate = "BEGINDATE");
  }

  return fieldDate;
}

function findUserIDInItem(item, filter) {
  let userId;
  let userField = filter.field_user;

  if (
    typeof item[userField] == "string" ||
    typeof item[userField] == "number"
  ) {
    userId = item[userField];
  } else if (typeof item[userField] == "object" && item[userField] != null) {
    let key = Object.keys(item[userField])[0];
    userId = item[userField][key];
  }

  return userId;
}

function sumFormat(sum) {
  sum = Math.round(+sum);
  // sum = new Intl.NumberFormat("ru-RU").format(+sum);

  return sum;
}

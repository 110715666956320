import router from "@/router";
import axios from "axios";

export default {
  state: {
    all: [],
    activeId: null,
  },
  mutations: {
    SET_ALL_PAGES(state, pages) {
      state.all = pages;
    },
    SET_ACTIVE_PAGE(state, id) {
      let activeId;

      if (state.all.length) {
        activeId = state.all[0].id;
      }

      if (localStorage.getItem("activePage")) {
        activeId = localStorage.getItem("activePage");
      }

      if (id) {
        activeId = id;
      }

      localStorage.setItem("activePage", activeId);
      router.push({ query: { page: activeId } }).catch(() => {});
      state.activeId = activeId;
    },
  },
  actions: {
    async getPages({ commit }) {
      let pages = await axios.get("pages");
      pages = pages.data;

      commit("SET_ALL_PAGES", pages);
      commit("SET_ACTIVE_PAGE");
    },
  },
};

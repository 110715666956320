import axios from "axios";

export default {
  state: {
    all: [],
    activeId: null,
  },
  mutations: {
    SET_ALL_TABS(state, payload) {
      state.all = payload;
    },
    SET_ACTIVE_TAB(state, id, reset) {
      let activeId;

      if (state.all.length) {
        activeId = state.all[0].id;
      }

      if (!reset) {
        if (localStorage.getItem("activeTab")) {
          activeId = localStorage.getItem("activeTab");
        }
      }

      if (id) {
        activeId = id;
      }

      console.log();

      localStorage.setItem("activeTab", activeId);
      state.activeId = activeId;
    },
  },
  actions: {
    async getTabs({ commit }, pageId) {
      if (!pageId) return false;

      let tabs = await axios.get(`tabs/${pageId}`);

      commit("SET_ALL_TABS", tabs.data);
      commit("SET_ACTIVE_TAB");
    },
  },
};

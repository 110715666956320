import axios from "axios";

export default {
  state: {
    filters: null,
    users: null,
    cols: {
      col1: [],
      col2: [],
      col3: [],
      col4: [],
    },
  },
  mutations: {
    RESET_SETTINGS_DATA(state) {
      state.filters = null;
    },
    SET_FILTERS_COLS(state, payload) {
      switch (payload.col) {
        case 1:
          state.cols.col1.push(payload);

          state.cols.col1.sort((a, b) => {
            return a.sort - b.sort;
          });
          break;
        case 2:
          state.cols.col2.push(payload);

          state.cols.col2.sort((a, b) => {
            return a.sort - b.sort;
          });
          break;
        case 3:
          state.cols.col3.push(payload);

          state.cols.col3.sort((a, b) => {
            return a.sort - b.sort;
          });
          break;
        case 4:
          state.cols.col4.push(payload);

          state.cols.col4.sort((a, b) => {
            return a.sort - b.sort;
          });
          break;
        default:
          state.cols.col1.push(payload);

          state.cols.col1.sort((a, b) => {
            return a.sort - b.sort;
          });
          break;
      }
    },
  },
  actions: {
    async getFilters({ state, commit, rootState }) {
      state.cols = {
        col1: [],
        col2: [],
        col3: [],
        col4: [],
      };

      let tabId = rootState.tabs.activeId;

      let filters = await axios.get(`filters_settings/${tabId}`);
      filters = filters.data.data;

      state.filters = filters;

      filters.forEach((filter) => {
        commit("SET_FILTERS_COLS", filter);
      });
    },

    async sortFilters(state, cols) {
      await axios.post("sort_filters", { cols: cols }).then((res) => {
        return res;
      });
    },

    async addFilter({ rootState }, col) {
      await axios
        .post("filters/" + rootState.tabs.activeId, {
          title: "new",
          col: col,
        })
        .then((res) => {
          return res;
        });
    },

    async saveFilter({ commit }, filter) {
      commit("LOADING", true);

      await axios.put("filters/" + filter.id, filter).then((res) => {
        commit("LOADING", false);
        return res;
      });
    },

    async deleteFilter(id) {
      await axios.delete("filters/" + id).then((res) => {
        return res;
      });
    },

    // tabs
    async addTab({ dispatch, rootState }) {
      let userId;
      if (localStorage.getItem("view_id")) {
        userId = localStorage.getItem("view_id");
      } else {
        userId = rootState.auth.user.id;
      }

      await axios.put(`tabs/${userId}`, { title: "new" }).then(() => {
        dispatch("getFiltersForSettings");
      });
    },
    async saveTabs({ rootState, commit }) {
      commit("LOADING", true);

      await axios.post("tabs", { tabs: rootState.tabs.all }).then((res) => {
        commit("SET_TABS", res.data);
        commit("LOADING", false);
      });
    },
    async deleteTab({ rootState, commit, dispatch }, index) {
      commit("LOADING", true);

      await axios
        .delete("tabs/" + rootState.tabs.data[index].id)
        .then(async () => {
          await dispatch("getTabs");
          commit("LOADING", false);
        });
    },
  },
};

import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// import excel from "vue-excel-export";

// Vue.use(excel);

Vue.config.productionTip = false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = store.state.url + store.state.apiPath;

let token = localStorage.getItem("token");

if (token == "null" || token == null) {
  localStorage.removeItem("token");
}

if (token)
  axios.defaults.headers.common["Authorization"] =
    "Bearer " + localStorage.getItem("token");

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

import axios from "axios";
import axiosRateLimit from "axios-rate-limit";

const rateLimit = axiosRateLimit(axios.create(), { maxRPS: 2 });

var moment = require("moment");
moment.locale("RU");

export default {
  state: {
    crmItems: {},
    fields: [],
    users: [],
  },
  mutations: {
    PUT_CRM_ITEMS(state, payload) {
      state.crmItems[`parentId${payload.entityTypeId}`] = payload.crmItems;
    },

    SET_FIELDS(state, payload) {
      state.fields = payload;
    },

    SET_STATUSES(state, payload) {
      state.statuses = payload;
    },
    SET_BITRIX_USERS(state, payload) {
      state.users = payload;
    },
  },
  actions: {
    async getCrmItems({ commit, rootState }, entityTypeId, filter) {
      if (!entityTypeId) return;

      let rqData = {
        entityTypeId: entityTypeId,
      };

      if (filter) {
        rqData.filter = filter;
      }

      let crmItems = await rateLimit.post(
        `${rootState.webhook}crm.item.list`,
        rqData
      );
      crmItems = crmItems.data.result.items;

      let result = {
        entityTypeId,
        crmItems,
      };

      commit("PUT_CRM_ITEMS", result);
    },

    async getItemFields({ commit, rootState }, filter) {
      let result, fieldsArr;

      if (filter.type == "crm.item.list") {
        result = await rateLimit.post(`${rootState.webhook}crm.item.fields`, {
          entityTypeId: filter.entity_type_id,
        });
        fieldsArr = Object.entries(result.data.result.fields);
      } else if (filter.type == "crm.deal.list") {
        result = await rateLimit.post(`${rootState.webhook}crm.deal.fields`);
        fieldsArr = Object.entries(result.data.result);
      } else {
        return;
      }

      let fields = [];

      fields.push({
        value: "",
        text: "",
        upperName: "",
        type: "",
        isMultiple: "",
      });

      fieldsArr.forEach((field) => {
        fields.push({
          value: field[0],
          text: field[1].title,
          upperName: field[1].upperName,
          type: field[1].type,
          isMultiple: field[1].isMultiple,
        });
      });

      commit("SET_FIELDS", fields);
    },

    async crmStatusList({ rootState, commit }) {
      let statusesData = await rateLimit.get(
        `${rootState.webhook}crm.status.list`
      );
      commit("SET_STATUSES", statusesData.data.result);
    },

    async getBitrixUsers({ commit, rootState }) {
      let usersBitrix = await rateLimit.post(
        `${rootState.webhook}user.get.json`,
        {
          filter: { active: "Y" },
        }
      );

      commit("SET_BITRIX_USERS", usersBitrix.data.result);
    },

    async sendMessage({ rootState }, data) {
      await rateLimit.post(`${rootState.webhook}log.blogpost.add`, data, {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Credentials": "true",
      });
    },
  },
};

import axios from "axios";
import axiosRateLimit from "axios-rate-limit";

const rateLimit = axiosRateLimit(axios.create(), { maxRPS: 2 });

var moment = require("moment");
moment.locale("RU");

export default {
  state: {
    filters: [],
    users: [],

    periodBegin: localStorage.getItem("periodBegin")
      ? new Date(localStorage.getItem("periodBegin"))
      : null,

    periodEnd: localStorage.getItem("periodEnd")
      ? new Date(localStorage.getItem("periodEnd"))
      : null,

    period: localStorage.getItem("period")
      ? String(localStorage.getItem("period"))
      : "this_month",

    statuses: null,

    tableView: {
      items: [],
      fields: null,
    },

    activeFilter: null,
    activeChart: null,
    tableItems: null,

    fields: null,

    created_at: null,
  },
  mutations: {
    SET_FILTERS(state, payload) {
      state.filters = payload;
    },
    SET_FILTER(state, payload) {
      state.filters.push(payload);
    },

    SET_ACTIVE_FILTER(state, payload) {
      if (payload) {
        state.activeFilter = payload;
        state.tableItems = payload.items;
        state.activeChart = payload.chart;
      } else {
        let num = 0;
        if (state.filters[num].empty == true) {
          while (state.filters[num].empty == true) {
            num++;
          }
        }

        // From auto next filter
        state.activeFilter = state.filters[num];
        state.tableItems = state.filters[num].items;
        state.activeChart = state.filters[num].chart;
      }
    },

    SET_DATE(state) {
      let today = new Date();
      var y = today.getFullYear(),
        m = today.getMonth();

      let periodBegin, periodEnd;

      if (state.period != "custom") {
        switch (state.period) {
          case "this_month":
            // This month
            periodBegin = new Date(y, m, 1);
            periodEnd = new Date(y, m + 1, 0);
            break;
          case "last_month":
            // Last month
            periodBegin = new Date(y, m - 1, 1);
            periodEnd = new Date(y, m, 0);
            break;
          case "year":
            // Year
            periodBegin = new Date(y, 0, 1);
            periodEnd = new Date(y, 11, 31);
            break;

          default:
            periodBegin = new Date(y, m, 1);
            periodEnd = new Date(y, m + 1, 0);
            break;
        }
      } else if (state.period == "custom") {
        periodBegin = new Date(state.periodBegin);
        periodEnd = new Date(state.periodEnd);
      }

      state.periodBegin = periodBegin;
      localStorage.setItem("periodBegin", periodBegin);

      state.periodEnd = periodEnd;
      localStorage.setItem("periodEnd", periodEnd);
    },

    TABLEVIEW_FIELDS(state, payload) {
      state.tableView.fields = payload;
    },
    TABLEVIEW_ITEMS(state, payload) {
      state.tableView.items = payload;
    },
    SET_USERS(state, payload) {
      state.users = payload;
    },
    SET_STATUSES(state, payload) {
      state.statuses = payload;
    },
    SET_FIELDS(state, payload) {
      state.fields = payload;
    },
    SET_STAGE_IDS(state, payload) {
      state.stageIds = payload;
    },
    SET_DASHBOARD_DATA(state, payload) {
      state.data = payload;
    },
  },
  actions: {
    async crmStatusList({ rootState, commit }) {
      let statusesData = await rateLimit.get(
        `${rootState.webhook}crm.status.list`
      );
      commit("SET_STATUSES", statusesData.data.result);
    },

    async dashboardPreparation({ commit, state, rootState }, reload = false) {
      let tabId = rootState.tabs.activeId;
      if (!tabId) return;

      let start = moment(state.periodBegin).format("DD.MM.YYYY");
      let end = moment(state.periodEnd).format("DD.MM.YYYY");

      let dashboard, localName;

      localName = `dashboard_t${tabId}_${start}_${end}`;

      if (!reload) {
        await axios.post("report", { key: localName }).then((res) => {
          dashboard = res.data;
        });
      }

      if (reload || !dashboard) {
        let data = {};
        data.params = { tabId: tabId, active: true };
        data.period = { start: start, end: end };
        data.key = localName;

        dashboard = await axios.post("dashboard", data).then((res) => {
          return res.data;
        });
      }

      state.created_at = dashboard.created_at;

      let tableFields = [];
      tableFields.push({ key: "date", label: "Дата" });

      for (let indicator of dashboard.data.indicators) {
        if (indicator.empty == true) {
          commit("SET_FILTER", indicator);
          continue;
        }
        let todaySum = 0;
        let todayItems = [];

        let fieldDate = findDate(indicator);

        indicator.data.items.forEach((item) => {
          let sum = findSumInItem(item, indicator);

          // Changes on day
          let startToday = new Date();
          startToday.setHours(0, 0, 0, 0);

          let endToday = new Date();
          endToday.setHours(23, 59, 59, 999);

          if (
            new Date(item[fieldDate]) >= startToday &&
            new Date(item[fieldDate]) <= endToday
          ) {
            todayItems.push(item);
            todaySum += +sum;
          }
        });

        indicator.items = indicator.data.items;
        indicator.sum = indicator.data.value;
        indicator.todayItems = todayItems;

        indicator.todaySum = operations(
          todaySum,
          indicator.operations,
          dashboard.data.indicators
        );

        indicator.sum = operations(
          indicator.sum,
          indicator.operations,
          dashboard.data.indicators
        );

        indicator.users = [];

        let chart = createChart(state, indicator, indicator.items);
        indicator.chart = chart;

        tableFields.push({
          key: String(indicator.id),
          label: indicator.title,
        });
      }

      // Indicators
      commit("SET_FILTERS", dashboard.data.indicators);

      // Table view
      let tableViewItems = tableView(state);
      commit("TABLEVIEW_FIELDS", tableFields);
      commit("TABLEVIEW_ITEMS", tableViewItems);

      for (const user of dashboard.data.users) {
        dashboard.data.indicators.forEach((filter) => {
          if (!filter.empty) {
            let items = [];
            let value = 0;

            // Search user items
            filter.data.items.forEach((item) => {
              // Find userId in item
              let userId = findUserIDInItem(item, filter);
              // Deal for user?
              if (userId == user.ID) {
                items.push(item);

                let sum = findSumInItem(item, filter);
                value += +sum;
              }
            });

            if (items.length == 0) {
              return;
            }

            value = operations(
              value,
              filter.operations,
              state.filters,
              user.ID
            );

            let chart = createChart(state, filter, items, 0, false);

            filter.users.push({
              user,
              items,
              value,
              chart,
            });

            filter.users.sort((a, b) => {
              return b.value - a.value;
            });
          }
        });
      }

      commit("SET_ACTIVE_FILTER");
    },
  },
};

function checkCurrency(sum) {
  if (sum.toString().includes("|RUB") || sum.toString().includes("|USD")) {
    sum = sum.replace(/[^0-9,.]/g, " ");
  }

  return sum;
}

// Operations in indicator
function operations(value, operations, filters, userBitrixId) {
  if (operations == null) return value;

  operations.forEach((operation) => {
    // Find value operation
    if (operation.from_filter) {
      let item;

      // Find index filter
      var index = filters.findIndex((el) => el.id == operation.from_filter);

      // If user operation
      if (userBitrixId) {
        // Find index filter
        let userIndex = filters[index].users.findIndex(
          (el) => el.data.ID == userBitrixId
        );

        item = filters[index].users[userIndex];
      } else {
        item = filters[index];
      }

      // Find value operation from variable
      var valueV;
      switch (operation.value) {
        case "$value":
          valueV = item.sum;
          break;
        case "$count":
          valueV = item.items.length;
          break;
        case "$items":
          valueV = item.items;
          break;
      }
    } else {
      valueV = operation.value;
    }

    // Logic operation
    switch (operation.action) {
      case "+":
        value += +valueV;
        break;
      case "-":
        value -= +valueV;
        break;
      case "/":
        value /= +valueV;
        break;
      case "*":
        value *= +valueV;
        break;
    }
  });

  return Math.round(value);
}

// Creating widgets and specific view
function createChart(state, filter, items, pointRadius = 4, withCount = true) {
  let chart = {
    labels: [],
    datasets: [],
  };

  let datasetSum = {
    label: "Сумма",
    borderColor: "rgba(0, 220, 125, 0.7)",
    backgroundColor: "rgba(0, 220, 125, 0.1)",
    pointRadius: pointRadius,
    data: [],
  };

  let datasetCount = {
    label: "Количество",
    borderColor: "rgba(126, 179, 255, 0.7)",
    backgroundColor: "rgba(126, 179, 255, 0.1)",
    pointRadius: pointRadius,
    data: [],
  };

  let date;

  var timeDiff = Math.abs(
    state.periodEnd.getTime() - state.periodBegin.getTime()
  );
  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (diffDays <= 93) {
    let periodStart = new Date(state.periodBegin);
    let periodEnd = new Date(state.periodEnd);

    while (periodStart <= periodEnd) {
      let value = 0;
      let count = 0;

      date = moment(periodStart).format("DD MMMM");
      chart.labels.push(date);

      items.forEach((item) => {
        let fieldDate = findDate(filter);

        if (
          moment(periodStart).format("DD.MM.YYYY") ==
          moment(item[fieldDate]).format("DD.MM.YYYY")
        ) {
          let sum = findSumInItem(item, filter);

          value += +sum;
          count += 1;
        }
      });

      // value = operations(
      //   value,
      //   filter.operations,
      //   state.filters
      // );

      value = sumFormat(value);

      datasetCount.data.push(count);
      datasetSum.data.push(value);

      periodStart.setDate(periodStart.getDate() + 1);
    }
  } else if (diffDays > 93) {
    let periodStart = new Date(state.periodBegin);
    let periodEnd = new Date(state.periodEnd);

    while (periodStart <= periodEnd) {
      date = moment(periodStart).format("MM.YYYY");

      let value = 0;
      let count = 0;

      chart.labels.push(date);

      items.forEach((item) => {
        let fieldDate = findDate(filter);

        if (
          moment(periodStart).format("MM.YYYY") ==
          moment(item[fieldDate]).format("MM.YYYY")
        ) {
          let sum = findSumInItem(item, filter);

          value += +sum;
          count += 1;
        }
      });

      // value = operations(
      //   value,
      //   filter.operations,
      //   state.filters
      // );

      value = sumFormat(value);

      datasetCount.data.push(count);
      datasetSum.data.push(value);

      periodStart.setMonth(periodStart.getMonth() + 1);
    }
  }

  if (withCount) {
    chart.datasets.push(datasetCount);
  }

  chart.datasets.push(datasetSum);

  return chart;
}

function tableView(state) {
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  let items = [];
  let dateText;

  let periodStart = new Date(state.periodBegin);
  let dayEnd = state.periodEnd.getDate();

  for (let i = 1; i <= dayEnd; i++) {
    let tableItem = {};

    // date = moment(periodStart).format("DD.MM.YYYY");
    dateText = moment(periodStart).format("DD MMMM");

    let value = 0;
    let count = 0;

    state.filters.forEach((filter) => {
      filter.items.forEach((item) => {
        let fieldDate = findDate(filter);

        if (
          moment(periodStart).format("DD.MM.YYYY") ==
          moment(item[fieldDate]).format("DD.MM.YYYY")
        ) {
          let sum = findSumInItem(item, filter);

          value += +sum;
          count += 1;
        }
      });

      // value = operations(
      //   value,
      //   filter.operations,
      //   filter
      // );

      tableItem[filter.id] = { value, count };
    });

    tableItem.date = dateText;
    items.push(tableItem);

    periodStart = periodStart.addDays(1);
  }

  return items;
}

// Find in
function findSumInItem(item, filter) {
  let sum = 0;
  let fieldSum;

  // Key field sum
  if (filter.field_sum != null) {
    fieldSum = filter.field_sum;
  } else {
    filter.type == "crm.item.list"
      ? (fieldSum = "opportunity")
      : (fieldSum = "OPPORTUNITY");
  }

  // Find value
  if (typeof item[fieldSum] == "string" || typeof item[fieldSum] == "number") {
    sum = item[fieldSum];
  } else if (typeof item[fieldSum] == "object" && item[fieldSum] != null) {
    let key = Object.keys(item[fieldSum])[0];
    sum = item[fieldSum][key];
  }

  // Remove currency from string
  sum = checkCurrency(sum);

  return sum;
}

function findDate(filter) {
  let fieldDate;

  if (filter.field_date != null) {
    fieldDate = filter.field_date;
  } else {
    filter.type == "crm.item.list"
      ? (fieldDate = "begindate")
      : (fieldDate = "BEGINDATE");
  }

  return fieldDate;
}

function findUserIDInItem(item, filter) {
  let userId;
  let userField = filter.field_user;

  if (
    typeof item[userField] == "string" ||
    typeof item[userField] == "number"
  ) {
    userId = item[userField];
  } else if (typeof item[userField] == "object" && item[userField] != null) {
    let key = Object.keys(item[userField])[0];
    userId = item[userField][key];
  }

  return userId;
}

function sumFormat(sum) {
  sum = Math.round(+sum);
  // sum = new Intl.NumberFormat("ru-RU").format(+sum);

  return sum;
}

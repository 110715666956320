<template>
  <div>
    <b-spinner
      v-if="loading"
      class="loading"
      variant="primary"
      label="Spinning"
    ></b-spinner>

    <navbar />

    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "App",
  components: { Navbar },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      url: (state) => state.url,
      csrfPath: (state) => state.csrfPath,
    }),
  },
  async mounted() {
    await axios.get(this.url + this.csrfPath);

    let token = localStorage.getItem("token");
    let version = localStorage.getItem("version");

    if (version != this.$store.state.version) {
      localStorage.clear();
      localStorage.setItem("version", this.$store.state.version);
      localStorage.setItem("token", token);
    }
  },
};
</script>

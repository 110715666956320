<template>
  <b-row class="indicator-row" align-h="start" align-v="start">
    <b-col
      v-for="(col, index) in cols"
      :key="index"
      cols="6"
      md="3"
      class="p-0"
    >
      <div v-for="(filter, index) in filtersInCol(col.num)" :key="index">
        <div v-if="filter.empty"></div>

        <div
          v-else
          @click="setActive(filter)"
          :class="activeFilter && filter.id == activeFilter.id ? 'active' : ''"
          class="indicator"
          :style="
            activeFilter && filter.id == activeFilter.id
              ? { 'outline-color': filter.color }
              : { outline: 'none' }
          "
        >
          <div class="indicator-data">
            <p class="indicator-title">
              {{
                filter.title.length > 28
                  ? `${filter.title.substr(0, 28)}...`
                  : filter.title
              }}
            </p>

            <div v-if="filter.items">
              <div v-if="filter.field_sum">
                <p
                  v-b-popover.hover.top="`${filter.items.length}`"
                  class="indicator-main-value"
                >
                  {{ sumFormat(filter.sum) }} ₽
                  <span
                    class="span-changes"
                    v-if="filter.todaySum != 0"
                    :class="filter.todaySum < 0 ? 'negative' : ''"
                  >
                    {{ filter.todaySum > 0 ? "+" : "-" }}
                    {{ sumFormat(Math.abs(filter.todaySum)) }}
                  </span>
                </p>
              </div>

              <div v-else>
                <p></p>
                <p class="indicator-main-value">
                  {{ filter.total >= 500 ? filter.total : filter.items.length }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FilterCards",
  data: () => ({
    cols: [{ num: 1 }, { num: 2 }, { num: 3 }, { num: 4 }],
  }),
  computed: {
    ...mapState({
      activeFilter: (state) => state.dashboard.activeFilter,
      indicators: (state) => state.dashboard.filters,
    }),
  },
  methods: {
    filtersInCol(col) {
      let indicators;
      indicators = this.indicators.filter((indicator) => indicator.col == col);

      indicators.sort((a, b) => {
        return a.sort - b.sort;
      });

      return indicators;
    },
    sumFormat(sum) {
      sum = new Intl.NumberFormat("ru-RU").format(+sum);
      return sum;
    },
    setActive(filter) {
      if (this.activeFilter.id == filter.id) {
        this.$bvModal.show("description");
      }

      this.$store.commit("SET_ACTIVE_FILTER", filter);
    },
  },
};
</script>

<style lang="scss">
.indicator {
  position: relative;
  z-index: 10;

  overflow: hidden;
  margin: 0 10px 20px 10px;

  border-radius: $radius;

  cursor: pointer;
  min-height: 150px;

  box-shadow: $shadow;

  outline-style: solid;
  outline-width: 2px;
}
.indicator-data {
  padding: 15px;
}
.indicator-title {
  font-size: 14px;
  padding-bottom: 15px;
}
.indicator-main-value {
  position: relative;
  font-size: 22px;
  font-weight: 700;

  margin-top: 10px;
}
.indicator-second-value {
  font-size: 18px;
  font-weight: 600;
}

.span-changes {
  position: absolute;
  top: -15px;
  right: 0;
  z-index: 100;

  font-weight: 500;
  font-size: 12px;

  color: green;
}
.span-changes.negative {
  color: red;
}
</style>

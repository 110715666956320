<template>
  <div class="dashboard-widget dashboard-navbar">
    <b-row align-v="center">
      <b-col cols="12" md="3">
        <b-form-select
          v-if="pages"
          v-model="activePageId"
          value-field="id"
          text-field="title"
          :options="pages"
        ></b-form-select>
      </b-col>

      <b-col v-for="(tab, index) in tabs" :key="index" cols="auto">
        <button
          class="tab"
          @click="setActiveTab(tab.id)"
          v-if="activeTab != null && !editingTabs"
          :class="tab.id == activeTab ? 'active' : ''"
        >
          <p class="tab-title">{{ tab.title }}</p>

          <p class="tab-badget" v-if="tab.id == activeTab">
            {{ countInTab }}
          </p>
        </button>

        <div>
          <input
            :class="tab.id == activeTab ? 'active' : ''"
            v-if="editingTabs"
            type="text"
            v-model="tab.title"
            required
          />
          <p @click="deleteTab(tab.id)" v-if="editingTabs">x</p>
        </div>
      </b-col>

      <b-col v-if="this.$route.name == 'Settings'" cols="auto">
        <button class="tab" @click="addTab()">
          <p class="tab_title">+</p>
        </button>
      </b-col>

      <b-col v-if="this.$route.name == 'Settings'" cols="auto">
        <button v-if="!editingTabs" @click="editTabs()">
          <p>Редактировать</p>
        </button>

        <button v-if="editingTabs" @click="saveTabs()">
          <p>Сохранить</p>
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "DashboardNav",
  data() {
    return {
      editingTabs: false,
    };
  },
  computed: {
    ...mapState({
      pages: (state) => state.pages.all,
      tabs: (state) => state.tabs.all,
      activeTab: (state) => state.tabs.activeId,
      filters: (state) => state.dashboard.filters,
    }),
    activePageId: {
      get() {
        return this.$store.state.pages.activeId;
      },
      set(pageId) {
        this.$store.commit("SET_ACTIVE_PAGE", pageId);
        this.$emit("page-changed", pageId);
      },
    },
    countInTab() {
      let countItems = 0;
      this.filters.forEach((filter) => {
        if (!filter.empty) countItems += filter.data.items.length;
      });

      return countItems;
    },
  },
  methods: {
    ...mapActions(["getFiltersForSettings", "dashboardPreparation"]),
    async setActiveTab(tabId) {
      this.$store.commit("LOADING", true);

      this.$store.commit("SET_ACTIVE_TAB", tabId);

      this.$emit("tab-changed", tabId);

      this.$store.commit("LOADING", false);
    },
    addTab() {
      this.$store.dispatch("addTab");
    },
    editTabs() {
      this.editingTabs = true;
    },
    deleteTab(tabId) {
      this.$store
        .dispatch("deleteTab", tabId)
        .then(() => {
          this.$bvToast.toast("Успешно удалено");
        })
        .catch(() => {
          this.$bvToast.toast("Ошибка при удалении");
        });
    },
    saveTabs() {
      this.editingTabs = false;
      this.$store
        .dispatch("saveTabs")
        .then(() => {
          this.$bvToast.toast("Сохранено");
        })
        .catch(() => {
          this.$bvToast.toast("Ошибка при сохранении");
        });
    },
  },
};
</script>

<style lang="scss">
.dashboard-navbar {
  margin-bottom: 30px;
}
.tab.active {
  font-weight: 700;
}
</style>

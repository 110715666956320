import axios from "axios";

import router from "../../router";

export default {
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
  },
  actions: {
    async login({ dispatch }, data = Object) {
      await axios
        .get(this.$store.state.url + this.$store.state.csrfPath)
        .then(async () => {
          const token = await axios
            .post("login", {
              email: data.email,
              password: data.password,
              device_name: "SPA",
            })
            .then((res) => {
              return res.data;
            });

          localStorage.setItem("token", token);

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          await dispatch("getUser");

          router.push({ name: "Dashboard" });
        });
    },

    async getUser({ commit }) {
      let res = await axios.get("user");

      commit("SET_USER", res.data);
    },
  },
};

<template>
  <div v-if="activeFilter" class="dashboard-widget">
    <b-row align-v="center" align-h="between">
      <b-col cols="12" class="widget-users">
        <div
          v-for="(user, index) in activeFilter.users"
          :key="index"
          @click="setActive(user, index)"
          :class="user.items == tableItems ? 'active' : ''"
          class="dashboard-user-indicator"
        >
          <b-row>
            <b-col cols="auto">
              <div class="user-indicator__photo-wrapper">
                <img
                  class="user-indicator__photo-img"
                  v-if="user.user.PERSONAL_PHOTO"
                  :src="user.user.PERSONAL_PHOTO"
                />

                <img
                  class="user-indicator__photo-img"
                  v-else
                  :src="require('@/assets/img/ui-user.svg')"
                  alt=""
                />
              </div>
            </b-col>

            <b-col cols="auto">
              <p
                v-b-popover.hover="`${user.items.length}`"
                v-if="activeFilter.field_sum"
                class="user-main-value"
              >
                {{ sumFormat(user.value) }} ₽
              </p>

              <p v-else class="user-main-value">
                {{ user.items.length }}
              </p>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "userCards",
  components: {},
  data() {
    return {
      activeUser: null,
      chartOptions: {
        legend: false,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              display: false,
              ticks: {
                maxTicksLimit: 5, // максимальное количество значений (и рёбер сетки) по оси y
              },
            },
          ],
          xAxes: [
            {
              display: false,
              ticks: {
                maxTicksLimit: 5, // максимальное количество значений (и рёбер сетки) по оси x
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState({
      activeFilter: (state) => state.dashboard.activeFilter,
      tableItems: (state) => state.dashboard.tableItems,
    }),
  },
  methods: {
    sumFormat(sum) {
      sum = new Intl.NumberFormat("ru-RU").format(+sum);
      return sum;
    },
    setActive(user, index) {
      this.$store.state.dashboard.tableItems = user.items;
      this.$store.state.dashboard.activeChart = user.chart;
      this.activeUser = index;
    },
  },
};
</script>

<style lang="scss">
.user-main-value {
  font-weight: 600;
  font-size: 20px;
}
.user-second-value {
  font-size: 16px;
  font-weight: 400;

  position: absolute;
  top: 0px;
  left: 0px;
}
.dashboard-user-indicator {
  padding: 5px;
}
.user-indicator__photo-wrapper {
  position: relative;
  overflow: hidden;

  height: 44px;
  width: 44px;

  border-radius: 100%;
}
.user-indicator__photo-img {
  height: 100%;
  width: 100%;

  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  object-fit: cover;
}

.widget-users {
  height: 50vh;
  overflow-y: scroll;
}
</style>

<template>
  <b-row v-if="filters && activeFilter && activeFilter.table">
    <b-col cols="12">
      <div class="dashboard-widget widget-table">
        <export-excel
          :data="tableItems"
          :fields="excelFields(activeFilter.table.fields)"
        >
          <button>Экспорт в excel</button>
        </export-excel>

        <b-table
          small
          bordered
          striped
          responsive
          :fields="activeFilter.table.fields"
          :items="tableItems"
          :sort-by.sync="activeFilter.table.sort_by"
          :sort-desc.sync="sort_desc"
        >
          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{ width: field.width != null ? field.width : '10vw' }"
            />
          </template>

          <template #cell()="data">
            {{ formatter(data) }}
          </template>

          <template #cell(STAGE_ID)="data">
            {{ findStatusName(data.item.STAGE_ID) }}
          </template>
          <template #cell(stageId)="data">
            {{ findStatusName(data.item.stageId) }}
          </template>

          <template #cell(DATE_CREATE)="data">
            {{ formatDate(data.item.DATE_CREATE) }}
          </template>
          <template #cell(date_create)="data">
            {{ formatDate(data.item.date_create) }}
          </template>

          <template #cell(BEGINDATE)="data">
            {{ formatDate(data.item.BEGINDATE) }}
          </template>
          <template #cell(begindate)="data">
            {{ formatDate(data.item.begindate) }}
          </template>

          <template #cell(CLOSEDATE)="data">
            {{ formatDate(data.item.CLOSEDATE) }}
          </template>

          <template #cell(createdTime)="data">
            {{ formatDate(data.item.createdTime) }}
          </template>

          <template #cell(UF_CRM_1586479785)="data">
            {{ formatDate(data.item.UF_CRM_1586479785) }}
          </template>

          <template #cell(parentId137)="data">
            {{ printable(data) }}
          </template>
        </b-table>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import ExportExcel from "../components/ExportExcel.vue";

export default {
  name: "FilterTable",
  components: { ExportExcel },
  data() {
    return {
      file1: null,
    };
  },
  computed: {
    ...mapState({
      filters: (state) => state.dashboard.filters,
      activeFilter: (state) => state.dashboard.activeFilter,
      users: (state) => state.dashboard.users,
      statuses: (state) => state.dashboard.statuses,
      crmItems: (state) => state.bitrix24.crmItems,
    }),
    tableItems: {
      get() {
        return this.$store.state.dashboard.tableItems;
      },
    },
    sort_desc: {
      get() {
        return Boolean(this.activeFilter.table.sort_desc);
      },
      set(value) {
        this.activeFilter.table.sort_desc = value;
      },
    },
  },
  methods: {
    ...mapActions(["sendMessage"]),
    excelFields(fieldsArr) {
      let excelFields = {};

      fieldsArr.forEach((field) => {
        excelFields[field.label] = field.key;
      });

      return excelFields;
    },
    formatter(data) {
      let key, value;

      key = data.field.key;
      key = key.toString();
      key = key.replace(/\s/g, "");

      value = data.item[key];

      if (key.includes(">date")) {
        key = key.replace(/>date/g, "");

        value = this.formatDate(data.item[key]);
      }

      if (key.includes(">money")) {
        key = key.replace(/>money/g, "");

        value = Math.round(data.item[key]);
        value = new Intl.NumberFormat("ru-RU").format(value);
      }

      return value;
    },
    findStatusName(statusId) {
      let statusIndex = null;
      let statusName = null;

      statusIndex = this.statuses.findIndex((el) => el.STATUS_ID == statusId);

      statusName = this.statuses[statusIndex].NAME;

      return statusName;
    },
    printable(cellData) {
      // field.key -> parentId137
      // cellData.value -> 36

      let needl = this.crmItems[cellData.field.key].find((el) => {
        return el.id == cellData.value;
      });

      return needl.title;
    },
    formatDate(date) {
      moment.locale("RU");
      return moment(date).format("DD MMMM YYYY");
    },
  },
};
</script>

<style lang="scss">
.widget-table {
  max-height: 50vh;
  overflow-y: scroll;
}
</style>

import Vue from "vue";
import Vuex from "vuex";
import dashboard from "@/store/modules/dashboard.js";
import payrollReport from "@/store/modules/payrollReport.js";
import auth from "@/store/modules/auth.js";
import settings from "@/store/modules/settings.js";
import tabs from "@/store/modules/tabs.js";
import pages from "@/store/modules/pages.js";
import bitrix24 from "@/store/modules/bitrix24.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    theme: "light",
    version: 1,
    loading: false,
    pages: null,

    // b24
    b24Url: "https://dbmaterials.bitrix24.ru/",
    webhook: "https://dbmaterials.bitrix24.ru/rest/342/bkxqdqm8t8l2zi97/",
    clientId: "local.6412a0150284a5.78721304",

    // api
    url: "https://dashboard.s-materials.ru/",
    apiPath: `laravel/v2/public/api/`,
    csrfPath: `laravel/v2/public/sanctum/csrf-cookie`,

    // local api
    // url: "http://127.0.0.1:8000/",
    // apiPath: `api/`,
    // csrfPath: `sanctum/csrf-cookie/`,
  },
  mutations: {
    LOADING(state, payload) {
      state.loading = payload;
    },
  },
  actions: {},
  modules: {
    auth,
    dashboard,
    settings,
    tabs,
    pages,
    payrollReport,
    bitrix24,
  },
});

<template>
  <div v-if="user" class="main-navbar">
    <b-container fluid>
      <b-row align-h="between" align-v="center">
        <b-col cols="12" md="auto">
          <b-row align-h="center">
            <button
              class="main-navbar__menu-button"
              @click="
                () => {
                  if ($route.name != link.name)
                    $router.push({ name: link.name });
                }
              "
              :class="[$route.name == link.name ? 'active' : '']"
              v-for="(link, index) in links"
              :key="index"
            >
              {{ link.label }}
            </button>
          </b-row>
        </b-col>

        <b-col cols="12" md="auto">
          <b-dropdown size="md" variant="link" no-caret v-if="user">
            <template #button-content>
              <b-row align-v="center" class="main-navbar-user">
                <div class="main-navbar-user__wrapper">
                  <img
                    class="main-navbar-user__img"
                    :src="`${
                      user.img ? user.img : require('@/assets/img/ui-user.svg')
                    }`"
                    v-if="user.img"
                  />
                </div>

                <p class="main-navbar-user__name">{{ user.name }}</p>
              </b-row>
            </template>

            <b-dropdown-item @click="logout()">Выйти</b-dropdown-item>
            <b-dropdown-item to="settings">Настройки</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "NavbarComp",
  data() {
    return {
      links: [
        { name: "Dashboard", label: "Дашборд" },
        { name: "PayrollReport", label: "Отчет по просроченным документам" },
        { name: "UserAssign", label: "Документы связанные с пользователем" },
        // { name: "ReportView", label: "Квартальный отчет по контрактам" },
        { name: "SMRView", label: "СМР" },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
  },
  methods: {
    async logout() {
      await axios.post("logout").then(() => {
        localStorage.removeItem("pageId");
        localStorage.removeItem("token");
        this.$router.push({ name: "Auth" });
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.main-navbar {
  padding: 0 30px;
  margin-bottom: 30px;

  border-bottom: 1.5px solid;
}
.main-navbar-user {
  padding: 0 15px;
}
.main-navbar-user__wrapper {
  position: relative;

  height: 42px;
  width: 42px;

  overflow: hidden;

  border-radius: 100%;
}
.main-navbar-user__img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  height: 100%;
  width: 100%;

  object-fit: cover;
}
.main-navbar-user__name {
  margin-left: 15px;

  line-height: normal;
}

.main-navbar__menu-button {
  margin-left: 15px;

  font-size: 14px;
  font-weight: 300;
}
.main-navbar__menu-button.active {
  font-weight: 600;
}
</style>

<template>
  <div class="dashboard-widget">
    <b-row align-h="between" align-v="center">
      <b-col cols="12" md="10">
        <b-row>
          <b-col cols="12" md="4">
            <b-form-select
              v-model="periodType"
              :options="types"
              value-field="value"
              text-field="title"
            ></b-form-select>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-datepicker
              :disabled="periodType && periodType != 'custom'"
              v-model="periodBegin"
              locale="ru"
              hide-header
              value-as-date
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }"
              start-weekday="1"
              button-variant="primary"
            ></b-form-datepicker>
          </b-col>

          <b-col cols="12" md="4">
            <b-form-datepicker
              :disabled="periodType && periodType != 'custom'"
              v-model="periodEnd"
              locale="ru"
              value-as-date
              hide-header
              :date-format-options="{
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }"
              start-weekday="1"
            ></b-form-datepicker>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <p v-if="created_at" class="dashboard-period-loaded">
              Последнее обновление:
              {{ created_at }}
            </p>
          </b-col>
        </b-row>
      </b-col>

      <b-col cols="12" md="auto">
        <button class="dashboard-period-button" @click="reload()">
          <svg
            class="dashboard-icon-svg"
            height="24px"
            width="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              class="dashboard-icon-path"
              d="M11.995 4.00001C7.8362 3.99432 4.36664 7.17599 4.01299 11.3197C3.65933 15.4634 6.53955 19.187 10.6391 19.8862C14.7387 20.5853 18.6903 18.0267 19.73 14H17.649C16.6318 16.8771 13.617 18.5324 10.6434 17.8465C7.66989 17.1605 5.68488 14.3519 6.03079 11.3199C6.3767 8.28792 8.94332 5.99856 11.995 6.00001C13.5845 6.00234 15.1064 6.64379 16.218 7.78002L13 11H20V4.00001L17.649 6.35002C16.1527 4.84464 14.1175 3.99873 11.995 4.00001Z"
            />
          </svg>
        </button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";

export default {
  name: "DashboardPeriod",
  data() {
    return {
      types: [
        {
          title: "Текущий месяц",
          value: "month",
        },
        {
          title: "Прошлый месяц",
          value: "last_month",
        },
        {
          title: "Текущий год",
          value: "year",
        },
        {
          title: "Произвольный",
          value: "custom",
        },
      ],
    };
  },
  computed: {
    created_at() {
      let date = this.$store.state.dashboard.created_at;

      if (date) {
        date = moment(date).format("hh:mm | DD MMM YYYY ");
      }

      return date;
    },
    periodType: {
      get() {
        return this.$store.state.dashboard.period;
      },
      async set(value) {
        this.$store.state.dashboard.period = value;
        localStorage.setItem("period", value);

        this.$store.commit("SET_DATE");

        if (value != "custom") {
          this.$store.commit("LOADING", true);

          await this.dashboardPreparation(true);
          this.$store.commit("LOADING", false);
        }
      },
    },
    periodBegin: {
      get() {
        return this.$store.state.dashboard.periodBegin;
      },
      set(value) {
        this.$store.state.dashboard.periodBegin = value;
      },
    },
    periodEnd: {
      get() {
        return this.$store.state.dashboard.periodEnd;
      },
      set(value) {
        this.$store.state.dashboard.periodEnd = value;
      },
    },
  },
  methods: {
    ...mapActions(["dashboardPreparation"]),
    async reload() {
      try {
        this.$store.commit("LOADING", true);

        this.$store.commit("SET_DATE");
        await this.dashboardPreparation(true);

        this.$store.commit("LOADING", false);
      } catch (e) {
        this.$store.commit("LOADING", false);
        this.$bvToast.toast(`Ошибка: ${e}`);
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.dashboard-period-loaded {
  font-size: 14px;
  padding-top: 15px;
}
</style>

<template>
  <div class="dashboard-view">
    <b-container>
      <DashboardPeriod />

      <DashboardNav
        @tab-changed="dashboardPreparation()"
        @page-changed="getTabs"
      />

      <div v-if="!asTable">
        <b-row align-h="between" align-v="start">
          <b-col cols="12" lg="9">
            <FilterCards />
          </b-col>

          <b-col cols="12" lg="3">
            <UserCards />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div v-if="activeChart" class="dashboard-widget">
              <Chart :chart-data="activeChart" />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <FilterTable />
          </b-col>
        </b-row>
      </div>

      <div v-if="asTable">
        <b-row>
          <b-col cols="12">
            <b-table
              small
              bordered
              no-border-collapse
              fixed
              responsive
              :fields="tableView.fields"
              :items="tableView.items"
              tbody-class="dashboard_tbody"
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width != null ? field.width : '10vw' }"
                />
              </template>

              <template #head()="data">
                <span>{{ data.label }}</span>
              </template>

              <template #cell()="data">
                <p class="table_view__value" v-if="data.value.value != null">
                  {{ sumFormat(Math.round(data.value.value)) }}
                </p>
                <p class="table_view__count" v-if="data.value.count != null">
                  {{ sumFormat(Math.abs(data.value.count)) }}
                </p>

                <p class="table_view__date" v-if="data.field.key == 'date'">
                  {{ data.value }}
                </p>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-modal
      id="description"
      size="xl"
      centered
      scrollable
      hide-footer
      hide-header
      body-class="modal_body__filter_description"
      content-class="modal_content__filter_description"
    >
      <b-container>
        <b-row>
          <b-col cols="12">
            <div v-if="activeFilter" v-html="activeFilter.description"></div>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import FilterCards from "../components/FilterCards.vue";
import UserCards from "../components/UserCards.vue";
import FilterTable from "../components/FilterTable.vue";
import Chart from "../components/Chart.vue";
import DashboardPeriod from "../components/DashboardPeriod.vue";
import DashboardNav from "../components/DashboardNav.vue";

export default {
  name: "DashboardView",
  components: {
    FilterCards,
    UserCards,
    FilterTable,
    Chart,
    DashboardPeriod,
    DashboardNav,
  },
  data() {
    return {
      asTable: false,
    };
  },
  computed: {
    ...mapState({
      activeFilter: (state) => state.dashboard.activeFilter,
      activeChart: (state) => state.dashboard.activeChart,
      tableView: (state) => state.dashboard.tableView,
      activePageId: (state) => state.pages.activeId,
    }),
  },
  methods: {
    ...mapActions([
      "crmStatusList",
      "dashboardPreparation",
      "getCrmItems",
      "getPages",
      "getTabs",
    ]),
    sumFormat(sum) {
      sum = new Intl.NumberFormat("ru-RU").format(+sum);
      return sum;
    },
  },
  async mounted() {
    try {
      this.$store.commit("LOADING", true);

      await this.getPages();
      await this.getTabs(this.activePageId);

      await this.crmStatusList();

      await this.getCrmItems(137); // получить смарт-процессы "Наша компания"

      await this.dashboardPreparation();

      this.$store.commit("LOADING", false);
    } catch (e) {
      this.$store.commit("LOADING", false);
      this.$bvToast.toast(`Ошибка: ${e}`);
      console.error(e);
    }
  },
};
</script>

<style lang="scss">
.dashboard-navbar {
  margin-bottom: 30px;
}
.tab {
  position: relative;
}
.tab-title {
  font-size: 13px;
}
.tab-badget {
  position: absolute;
  top: -20px;
  right: -20px;

  font-size: 11px;
  color: rgba($white, 1);
  font-weight: 500;

  background-color: rgba($red, 1);
  border-radius: 100%;

  padding: 5px;
  height: 24px;
  width: 24px;
}
.dashboard-block-title {
  padding-bottom: 15px;
  padding-left: 15px;
  font-size: 14px;
}
</style>

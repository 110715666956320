import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/DashboardView.vue";
import store from "@/store";

Vue.use(VueRouter);

let path = "/";

const routes = [
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
  // Auth
  {
    path: `${path}auth`,
    name: "Auth",
    meta: { auth: false },
    component: () => import("@/views/AuthView.vue"),
  },
  {
    path: `${path}register`,
    name: "Register",
    meta: { auth: false },
    component: () => import("@/views/RegisterView.vue"),
  },
  // Main
  {
    path: `${path}`,
    name: `Dashboard`,
    meta: { auth: true },
    component: Dashboard,
  },
  {
    path: `${path}report`,
    name: "ReportView",
    meta: { auth: true },
    component: () => import("@/views/ReportView.vue"),
  },
  {
    path: `${path}payroll_report`,
    name: "PayrollReport",
    meta: { auth: true },
    component: () => import("@/views/PayrollReportView.vue"),
  },
  {
    path: `${path}user_assign`,
    name: "UserAssign",
    meta: { auth: true },
    component: () => import("@/views/UserAssignView.vue"),
  },
  {
    path: `${path}smr`,
    name: "SMRView",
    meta: { auth: true, admin: false },
    component: () => import("@/views/SMRView.vue"),
  },

  // Admin
  {
    path: `${path}settings`,
    name: "Settings",
    meta: { auth: true, admin: true },
    component: () => import("@/views/SettingsView.vue"),
  },
  {
    path: `${path}user_settings`,
    name: "UserSettings",
    meta: { auth: true, admin: true },
    component: () => import("@/views/UserSettingsView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  let user;
  let requiredAuth = to.meta.auth;
  let adminRoute = to.meta.admin;
  let token = localStorage.getItem("token");

  if (token) {
    try {
      await store.dispatch("getUser");
      user = store.state.auth.user;
    } catch (e) {
      console.error(e);
    }
  }

  if (requiredAuth && !user) {
    next({ name: "Auth" });

    if (adminRoute && !user.admin) {
      next({ name: "Dashboard" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
